import api from '../axios';
import config from '@/constants/config';
const resource = '/api/mp/settings/';

export default {
  // shift for calendar
  userList: (filterQuery,role) => api.get(`${resource}users?${filterQuery}filter[role_name]=${role}`),
  userManageList: (filterQuery) => api.get(`${resource}users/user-management?${filterQuery}`),
  userListForScheduler: (filterQuery) => api.get(`${resource}users/user-for-scheduler?${filterQuery}`),
  locumList: (filterQuery) => api.get(`${resource}users/locums?${filterQuery}`),

  createShift: (payload) => api.post(`/api/mp/shift`, payload),
  createVacantShift: (payload) => api.post(`/api/mp/shift/vacant`, payload),

  getCalenderData: (start, end, service_type = null) =>
    api.get(
      `/api/mp/shift?start=${start}&end=${end}&service_type=${service_type}`
    ),

  getShiftCount: (payload) =>
    api.post(`/api/mp/shift/count` , payload ),

  getUserLeaves: (payload) => api.post(`/api/mp/shift/leaves`, payload),

  getUsersUnAvailabilities: (payload) =>
    api.post(`/api/mp/shift/un-availabilities`, payload),

  getServicesInPractice: (filter) =>
    api.get(`/api/mp/services`),

  getShiftTypesInPractice: (filter) => api.get(`/api/mp/shift-types?`),

  getServicesInPracticeWithoutFilter: () => api.get(`/api/mp/services`),

  updateShift: (shiftId, payload) =>
    api.put(`/api/mp/shift/${shiftId}`, payload),

  deleteEvent: (shiftId) => api.delete(`/api/mp/shift/${shiftId}`),

  publishData: (payload) => api.post('api/mp/shift/publish', payload),

  getApplicationsForVacantShifts: (shift_id) =>
    api.get(
      `api/mp/practice-admin/shift-applications?filter[shift_id]=${shift_id}`
    ),

  acceptApplicationInvitation: (id) =>
    api.post(`api/mp/practice-admin/shift-applications/${id}/accept`),

  rejectApplicationInvitation: (id, user_id) =>
    api.post(
      `api/mp/practice-admin/shift-applications/${id}/reject/${user_id}`
    ),

  duplicateMultipleDays: (payload) =>
    api.post(`api/mp/shift/duplicate`, payload),

  //salary staff API
  myShift: (start, end, service_type = null) => api.get(`api/mp/my-shifts?start=${start}&end=${end}`),
  appliedShifts: (start, end, service_type = null) => api.get(`api/mp/salaried-staff/shifts/applied?start=${start}&end=${end}`),

  cancelSift: (id) => api.post(`api/mp/my-shifts/${id}/cancel`),

  // getSwapShift:(role_id) => api.get(`api/mp/salaried-staff/shifts/all?filter[job_role_id]=${role_id}`),
  swapShift: (payload) =>
    api.post(`api/mp/salaried-staff/shifts/swap`, payload),

  getSwapRequestList: () => api.get(`api/mp/salaried-staff/shifts/swap`),

  swapRequestAccept: (id) =>
    api.post(`api/mp/salaried-staff/shifts/swap/${id}/accept`),

  swapRequestReject: (id) =>
    api.post(`api/mp/salaried-staff/shifts/swap/${id}/reject`),

  getSwapShift: (shift_id, role_id) =>
    api.get(
      `api/mp/salaried-staff/shifts/${shift_id}/swappable?filter[job_role_id]=${role_id}`
    ),

  getApplicationListVacantShifts: (filterQuery) =>
    api.get(
      `api/mp/salaried-staff/shifts/applications/vacant-shifts?${filterQuery}`
    ),

  getPCNApplicationListVacantShifts: (filterQuery, page, paginate) =>
    api.get(
      `api/mp/salaried-staff/shifts/applications/pcn-vacant-shifts?${filterQuery}&page=${page}&paginate=${paginate}`
    ),

  applyVacantShift: (id) =>
    api.post(`api/mp/salaried-staff/shifts/applications/${id}/apply`),

  applyPCNVacantShift: (id) =>
    api.post(
      `api/mp/salaried-staff/shifts/applications/${id}/apply/pcn-vacant`
    ),

  getShiftTemplateWithoutPagination: () => api.get(`${resource}shift_template/without-pagination`),
};
